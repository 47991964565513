import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wafer and photomask handling`}</h4>
    <p>{`Storing and transporting wafers and photomasks in a fab requires specific equipment. Although specialized, this equipment is less complex than other tools used in semiconductor manufacturing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      